[role="dialog"] {
    .dialog {
        &__title {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__close {
            color: var(--secondary-main);
        }

        &__content {
            padding: 15px 20px;
        }

        &__actions {
        }

        &__buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0 12px;
        }

        &__button {
            min-width: 100px;
            &.cancel {
                min-width: unset;
            }

            &.save {
            }

            &.delete {
            }
        }
    }
}
