.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;

    &.reload-page {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;

        .beer-loading {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 120px;
            height: 175px;
            border-radius: 15px;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            background-position: 0 430px;
            animation: fill 4s ease-in-out infinite;
            box-shadow: 0 0 0 13px #262626, 0 20px 35px rgba(0, 0, 0, 1);

            &::before {
                content: "";
                position: absolute;
                width: 45px;
                height: 80px;
                border: 14px solid #262626;
                right: -73px;
                top: 30px;
                border-top-right-radius: 35px;
                border-bottom-right-radius: 35px;
            }

            @keyframes fill {
                20% {
                    background-position: -100px 230px;
                }

                100% {
                    background-position: 1600px 450px;
                }
            }
        }
    }
}

.loading-component {
    transform: translateY(50px);
}
