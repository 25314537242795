.outside-layout {
    .layout {
        &__wrapper {
        }

        &__header {
            position: relative;
            z-index: 2;
            header {
                border-bottom: 2px solid var(--bg-light);
            }
        }

        &__content {
            background: var(--bg-primary-dark);
            height: 100vh;
        }

        &__container {
            position: relative;
            height: inherit;
            padding-top: 70px;
        }
    }
}
