.page-events {
    .events {
        &__wrapper {
        }

        &__controls {
            background: var(--bg-light);
            border-radius: var(--radius);
            .controls {
                &__image {
                    height: 350px;
                    border-radius: var(--radius) var(--radius) 0 0;

                    background-size: cover;
                    background-position: center;
                }

                &__description {
                    padding: 15px;
                    text-align: center;
                    p {
                        margin: 0;
                    }
                }

                &__actions {
                    padding: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    button {
                    }
                }
            }
        }

        &__list {
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

.page-singleevent {
    .event {
        &__wrapper {
            margin-bottom: 40px;
        }
        &__image {
            height: 350px;
            border-radius: var(--radius);

            background-size: cover;
            background-position: center;

            transition: opacity 0.4s linear;

            &.completed {
                position: relative;

                &::before {
                    content: "Подія завершена";
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                    background: var(--disabled-mui);
                    border-radius: var(--radius);
                    font-size: 30px;
                    color: var(--secondary-contrastText);

                    animation: show-completed-before 0.4s linear forwards;
                }

                @keyframes show-completed-before {
                    from {
                        opacity: 0;
                    }
                    to {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .form {
        &__wrapper {
            display: grid; // https://grid.layoutit.com/
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(5, auto);
            gap: 20px;
            grid-auto-flow: row;
            grid-template-areas:
                ". ."
                ". ."
                "full1 full1"
                "full2 full2"
                "full3 full3";

            .full1 {
                grid-area: full1;
            }
            .full2 {
                grid-area: full2;
            }
            .full3 {
                margin-top: 50px;
                grid-area: full3;
            }
        }
        &__field {
            // width: 48%;
            // margin-bottom: 15px;
            &.input {
            }
            &.select {
                width: 100%;
            }
        }
        &__checkbox {
            // margin-top: 10px;

            .text {
                margin-right: 10px;
                color: var(--primary-contrastText);

                &.disabled {
                    color: var(--disabled-mui);
                }
            }
        }
        &__button {
            max-width: 180px;
        }
    }
}
