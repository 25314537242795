body.login {
    overflow: hidden;
}

.login-page {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .login {
        &__wrapper {
        }
        &__particle {
            width: 100vw;
            height: 100vh;
            position: absolute;
            overflow: hidden;
        }
        &__button {
            transform: scale(0.3);
            opacity: 0;
            animation: show-up 1s cubic-bezier(0.39, 0.58, 0.56, 1.01) 0.5s
                forwards;

            overflow: hidden;
            color: #fff;
            border-color: #fff;
            background: rgba(0, 0, 0, 0.7);
            font-weight: bold;
            text-transform: none;
            border-width: 2px;

            .icon {
                position: relative;
                height: 30px;
                width: 30px;
                left: 50%;
                transform: translateX(-50%) rotate(360deg);

                animation: animate-icon-from 0.8s
                    cubic-bezier(0.18, 0.89, 0.32, 1.28) 1.57s forwards;
            }
            .text {
                position: relative;
                margin-left: 6px;
                transform: translateX(200%);
                animation: animate-text-from 0.9s ease-in-out 1s forwards;
            }

            &.loaded {
                .icon {
                    animation-delay: 0s;
                    animation-duration: 1s;
                }
                .text {
                    animation-delay: 0s;
                    animation-duration: 0.4s;
                }
            }

            &:hover {
                border-width: 2px;
                background: rgba(0, 0, 0, 0.7);
                // border-color: #fff;
            }

            &.Mui-disabled {
                color: inherit;
                border-color: #fff;

                .icon {
                    left: 0;
                    transform: translateX(0) rotate(0);
                    animation: animate-icon-to 0.8s
                        cubic-bezier(0.18, 0.89, 0.32, 1.28) 0.1s forwards;

                    svg {
                        animation: animate-icon-rotate 1.3s
                            cubic-bezier(0.18, 0.89, 0.32, 1.28) 0.25s infinite;
                    }
                }

                .text {
                    transform: translateX(0);
                    animation: animate-text-to 0.9s ease-in-out forwards;
                }
            }
        }
        &__progress {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    @keyframes show-up {
        0% {
            transform: scale(0.3);
            opacity: 0;
        }
        100% {
            transform: scale(1);
            opacity: 1;
        }
    }

    @keyframes animate-icon-from {
        from {
            left: 50%;
            transform: translateX(-50%) rotate(360deg);
        }
        to {
            left: 0;
            transform: translateX(0) rotate(0);
        }
    }
    @keyframes animate-icon-to {
        from {
            left: 0;
            transform: translateX(0) rotate(0);
        }
        to {
            left: 50%;
            transform: translateX(-50%) rotate(360deg);
        }
    }

    @keyframes animate-icon-rotate {
        from {
            transform: rotate();
        }
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes animate-text-from {
        from {
            transform: translateX(200%);
        }
        to {
            transform: translateX(0);
        }
    }
    @keyframes animate-text-to {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(200%);
        }
    }
}
