@import "./variables.scss";
@import "./forms.scss";

body {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    margin: 0;
    background: var(--bg-primary-dark);
    color: var(--text-primary-main);

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: var(--bg-light);
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-clip: content-box;
        background-color: var(--primary-main);
    }

    .default-link {
        text-decoration: none;
        color: var(--text-primary-main) !important;
        &:visited {
            color: inherit;
        }
    }

    .item-link {
        padding: 10px;
    }
    .link-name {
    }

    .MuiButton-root {
        border-radius: var(--radius-button);
    }
    .MuiFormHelperText-root {
        margin-left: 0;
    }
}
