body.welcome {
    @import url("https://fonts.googleapis.com/css2?family=Rubik+Moonrocks&display=swap");
    overflow: hidden;
    .layout__header {
        // TODO :not(.exist) {}
        transform: translateY(-70px);
        animation: hide-header 0.8s 5s ease forwards;

        @keyframes hide-header {
            from {
                transform: translateY(-70px);
            }
            to {
                transform: translateY(0);
            }
        }
    }

    .game {
        &__loader {
            display: none;
            position: absolute;
            bottom: 5%;
            left: -5%;
            width: 50px;
            height: 50px;
            animation: road 10s 10s linear infinite;

            &:hover {
                .box {
                    background: var(--primary-main);
                    border-radius: 15px;
                }
                .shadow {
                    opacity: 1;
                }
            }

            .box {
                width: 50px;
                height: 50px;
                background: var(--secondary-main);
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 3px;
                animation: box-rorate 0.5s linear infinite;
                transition: 0.4s ease;
            }

            .shadow {
                width: 50px;
                height: 5px;
                background: var(--lt-color-gray-400);
                opacity: 0.1;
                position: absolute;
                top: 59px;
                left: 0;
                border-radius: 50%;
                transition: 0.4s ease;
                animation: shadow-box 0.5s linear infinite;
            }
        }
    }
}
.welcome-page {
    canvas {
        width: 100%;
        height: 100%;
        max-height: 800px;
        opacity: 0;
        animation: canva-show 2s ease forwards;

        @keyframes canva-show {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    }

    @keyframes box-rorate {
        17% {
            border-bottom-right-radius: 3px;
        }
        25% {
            transform: translateY(9px) rotate(22.5deg);
        }
        50% {
            transform: translateY(18px) scale(1, 0.9) rotate(45deg);
            border-bottom-right-radius: 40px;
        }
        75% {
            transform: translateY(9px) rotate(67.5deg);
        }
        100% {
            transform: translateY(0) rotate(90deg);
        }
    }

    @keyframes shadow-box {
        50% {
            transform: scale(1.2, 1);
        }
    }

    @keyframes road {
        from {
            left: -5%;
        }
        to {
            left: 105%;
        }
    }

    .welcome {
        &__wrapper {
            position: relative;
        }

        &__content {
            position: absolute;
            bottom: 130px;
            width: 100%;
        }

        &__title {
            // &:not(.exist) {
            opacity: 0;
            transform: translateY(200px) scale(0.6);
            transition: 0.3s ease;

            animation: show-title 2s 5s ease forwards;
            // }
            font-family: "Rubik Moonrocks", cursive;
        }

        &__text {
            max-width: 600px;
            margin: 0 auto;
            text-align: center;
            color: var(--secondary-contrastText);

            // &:not(.exist) {
            opacity: 0;
            transform: translateY(200px) scale(0.6);
            animation: show-text 2s 5.5s ease forwards;
            // }
        }

        &__buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 40px;

            // &:not(.exist) {
            opacity: 0;
            transform: translateY(200px) scale(0.6);
            animation: show-text 2s 6s ease forwards;
            // }
        }

        @keyframes show-title {
            from {
                opacity: 0;
                transform: translateY(200px) scale(0.6);
            }
            to {
                opacity: 1;
                transform: translateY(0) scale(1);
            }
        }
        @keyframes show-text {
            from {
                opacity: 0;
                transform: translateY(200px) scale(0.6);
            }
            to {
                opacity: 1;
                transform: translateY(0) scale(1);
            }
        }
    }
}
