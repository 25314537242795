.page-maintenance {
    display: flex;
    align-items: center;
    justify-content: center;

    .hareket {
        color: var(--success);
        font-family: Monaco, monospace;
        font-size: 24px;
        width: 100%;
        text-align: left;
        animation: 120ms infinite normal glitch;
    }
    span {
        animation: 1.5s infinite normal imlec;
    }
    ::-moz-selection {
        background: #7021d2;
        color: #fff;
    }
    ::selection {
        background: #7021d2;
        color: #fff;
    }
    @keyframes glitch {
        0% {
            opacity: 0;
            left: 0;
        }
        40%,
        80% {
            opacity: 1;
            left: -2px;
        }
    }
    @keyframes imlec {
        0% {
            opacity: 0;
            left: 0;
        }
        40% {
            opacity: 0;
            left: -2px;
        }
        80% {
            opacity: 1;
            left: -2px;
        }
    }
    .bilmm {
        display: flex;
        align-items: center;
        height: 500px;
    }
}
