.form {
    &__field {
        &.select {
            svg.MuiSvgIcon-root {
                color: var(--primary-main);

                &.Mui-disabled {
                    color: rgba(0, 0, 0, 0.26);
                }
            }
            // &::before {
            //     border-bottom-color: var(--secondary-contrastText);
            // }
        }

        .MuiInput-root {
            // &::before {
            //     border-bottom-color: var(--secondary-contrastText);
            // }
        }
    }

    &__box {
        &-button {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 100%;
        }
    }

    &__divider {
        width: 100%;
        hr {
            display: block;
            height: 1px;
            border: 0;
            // border-top: 2px solid var(--bg-light);
            border-top: 1px solid rgba(0, 0, 0, 0.42);
            margin: 0;
            padding: 0;
        }
    }
}

.select {
    .MuiPopover-paper.MuiMenu-paper {
        border-radius: 0 0 8px 8px;
    }
}
