.breadcrumbs {
    display: flex;
    align-items: center;
    margin-bottom: 20px !important;
    min-height: 24px; // when breadcrumbs hidden

    .MuiBreadcrumbs-ol {
        .MuiBreadcrumbs-li {
            a {
                color: var(--primary-contrastText);
                text-decoration: none;
            }
        }
        .MuiBreadcrumbs-separator {
            color: var(--primary-main);
        }
    }
}
