.form__wrapper {
    // MUI
    .datepicker {
        .MuiIconButton-root:not(.Mui-disabled) {
            svg {
                color: var(--primary-main);
            }
        }
    }
}
