.dashboard-layout {
    overflow: hidden;
    &__main {
        min-height: 100vh;
        display: flex;
    }
    &__sidebar {
    }
    &__container {
        padding-left: var(--padding-main-content);
        padding-top: 70px;
        background: var(--bg-primary-dark);
        width: 100%;

        &.fullsize {
            padding-left: 0;
        }
    }
    &__content {
        padding: 15px;
    }
}
