.donate-page {
    display: flex;
    align-items: center;
    justify-content: center;
    .card {
        &__wrapper {
            max-width: 500px;
            padding: 20px;
            border-radius: var(--radius);
            background: var(--bg-light);
        }
        &__heading {
            text-align: center;
            font-size: 22px;
            margin-bottom: 10px;
        }
        &__description {
            text-align: center;
        }
        &__link {
            color: var(--secondary-main);
            font-size: 18px;
            text-align: center;
            a {
                color: inherit;
            }
        }
        &__qrcode {
        }
    }
}
