.dashboard-layout,
.outside-layout {
    .header {
        &__wrapper {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            padding-left: var(--padding-main-content);

            background-color: var(--bg-primary-dark);

            &.fullsize {
                padding-left: 0;
            }

            &.fixed-header {
                box-shadow: none;
            }

            &.free-header {
            }
        }

        &__title {
            &-link {
                text-decoration: none;
                color: var(--text-primary-main);
            }
        }

        &__setting {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}
