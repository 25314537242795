.dashboard {
    .general-info {
        &__wrapper {
        }
    }

    .features {
        &__wrapper {
        }
        &__container {
            margin-bottom: 10px;
        }
        &__paper {
            padding: 20px;
            min-width: 300px;
        }
        &__heading {
        }
        &__description {
        }
    }
}
