.table-container {
    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: var(--bg-light);
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0;
        background-clip: content-box;
        background-color: var(--secondary-main);
    }

    .table-row-disabled {
        position: relative;
        background: var(--disabled-main);

        &:hover {
            background: var(--disabled-main) !important;
        }
        .table-text-completed {
            color: var(--error);
        }
    }
    .table-button-disabled {
    }
}
