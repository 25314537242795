.page-adminpanel {
    .admin {
        &__wrapper {
        }

        &__lists {
            min-height: 300px;
            display: flex;
            align-items: center;
            justify-content: center;
            .user {
                &__list {
                    width: 100%;
                }

                &__item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 20px;
                    border-radius: var(--radius);
                    &:not(:last-child) {
                        margin-bottom: 25px;
                    }

                    background: var(--bg-secondary-dark);
                }

                &__name {
                    max-width: 260px;
                    width: 100%;
                }

                &__email {
                    max-width: 300px;
                    width: 100%;
                }

                &__role {
                    max-width: 60px;
                    width: 100%;
                    &.admin {
                        color: var(--secondary-main);
                    }

                    &.user {
                        color: var(--success);
                    }

                    &.guest {
                        color: var(--warning);
                    }
                }

                &__nickname {
                    color: var(--secondary-contrastText);
                }

                &__actions {
                }

                &__button {
                }
            }
        }
    }
}
body.adminpanel {
    .form {
        &__wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;

            & > div {
                flex: 45%;
                margin-bottom: 15px;

                &:nth-child(odd) {
                    margin-right: 15px;
                }
            }
        }

        &__field {
        }

        &__buttons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: 0 !important;
        }

        &__button {
            width: 100px;
        }
    }
}
